import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './benefit.css'
import {message} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {SearchOutlined} from '@ant-design/icons';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";

let headers = [
  { label: "#", key: "id" },
  { label: "arabicTitle", key: "title_ar" },
  { label: "englishTitle", key: "title_en" },
  { label: "arabicDescription", key: "description_ar" },
  { label: "englishDescription", key: "description_en" },
  { label: "img", key: "img" }
];
 
let csvData = [
  
];
class benefit extends React.Component {
  
  state={
    benefit:[],
    benefit2:[],
    loading:true,
    refresh:false,
    search:null,
    page:1,
    pages:1,
    csvData:[]
  }

  constructor(props){
    super(props)
    this.csvLinkEl = React.createRef();
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    this.getBenefits(1)
    this.getBenefitsWithoutPagenation()
  }


  getBenefits = (page,rest) =>{
    this.setState({loading:true})
    let url =`${BASE_END_POINT}benefits?page=${page}`
    let {search} = this.state
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    if(rest) url = `${BASE_END_POINT}benefits?page=${page}`

    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("benefit   ",response.data)
      var data = response.data.data.map(item=>[
        item.id,
        item.title_ar,
        item.title_en,
        item.img?{type:"img",img:item.img}:{type:"img",img:""},
        "action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        benefit:response.data.data,
        benefit2:data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getBenefitsWithoutPagenation = (rest) =>{
    let url = `${BASE_END_POINT}benefits/withoutPagenation/get?page=1`
    let {search} = this.state
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    if(rest) url = `${BASE_END_POINT}benefits/withoutPagenation/get`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("deals   ",response.data)
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{title_ar:item.title_ar?item.title_ar:''},
            ...{title_en:item.title_en?item.title_en:''},
            ...{description_ar:item.description_ar?item.description_ar:''},
            ...{description_en:item.description_en?item.description_en:''},
            ...{img:item.img?item.img :''},

          };
        });
        this.setState({csvData:csvData})
      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }
  deletebenefit = (id) => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}benefits/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`
     },
    })
    .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getBenefits(this.state.page,true)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="AddBenefit">
        <Fab
          style={{background:'#233b5b',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getBenefits(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getBenefits(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getBenefits(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#233b5b !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getBenefits(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getBenefits(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('BEBEFITS')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
            
          <Table
            title={allStrings.benefits}
            icon='spa'
            settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.setState({search:null,status:null});this.getBenefits(1,true);this.getBenefitsWithoutPagenation(true)}}
                class="material-icons">
                  replay
                </span>
                <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"benefits.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img crossorigin="anonymous"alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
              </div>
              
            }
            filter ={
              <div>
                <div className={this.state.showFilter?'showClass160':'hideClass'}>
                  <MDBRow>
                    <MDBCol xl="12" lg="12" md="12" sm="12">
                      <div class="md-form">
                      <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',fontWeight:'300'}} type="text" id="form605"  class="form-control"
                        placeholder={allStrings.Search}
                        onChange={(e)=>{
                          this.setState({search:e.target.value})
                        }}  
                      />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                  <Button style={{background: '#233b5b',color: '#fff',width: '100%',margin: '32px auto'}} 
                        onClick={()=>{this.getBenefits(1,false);this.getBenefitsWithoutPagenation()}}><SearchOutlined /> {allStrings.Search}</Button>
                  </MDBRow>
                  
                </div>
              </div>
            }
            data={this.state.benefit}
            data2={this.state.benefit2}
            tableColumns={["#",allStrings.arabicTitle,allStrings.englishTitle,allStrings.img,allStrings.action]}
            loading={this.state.loading}
            deleteUser={(id)=>{this.deletebenefit(id)}}
            path='/benefitInfo'
            actionIndex={3}
            />
            {this.paginationButtons()}
            {this.addNewRecordButton()}
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(benefit)
);

