import React from 'react';
import './permission info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message } from "antd";
class permissionInfo extends React.Component {
      state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
          id:this.props.location.state.data.id,
          permission_en:this.props.location.state.data.permission_en,
          permission_ar:this.props.location.state.data.permission_ar,
          pages:this.props.location.state.data.pages,
          type:this.props.location.state.data.type,

          showSettings:this.props.location.state?this.props.location.state.data.pages.includes('SETTINGS') ?true:false:false,
          showDashboard:this.props.location.state?this.props.location.state.data.pages.includes('DASHBOARD') ?true:false:false,
          showContactUs:this.props.location.state?this.props.location.state.data.pages.includes('CONTACT-US') ?true:false:false,
          showDeals:this.props.location.state?this.props.location.state.data.pages.includes('DEALS') ?true:false:false,
          showProject:this.props.location.state?this.props.location.state.data.pages.includes('PROJECTS') ?true:false:false,
          showCountries:this.props.location.state?this.props.location.state.data.pages.includes('COUNTRIES') ?true:false:false,
          showAbout:this.props.location.state?this.props.location.state.data.pages.includes('ABOUT') ?true:false:false,
          showLocations:this.props.location.state?this.props.location.state.data.pages.includes('LOCATIONS') ?true:false:false,
          showUsers:this.props.location.state?this.props.location.state.data.pages.includes('USERS') ?true:false:false,
          showCategories:this.props.location.state?this.props.location.state.data.pages.includes('CATEGORIES') ?true:false:false,
          showFaq:this.props.location.state?this.props.location.state.data.pages.includes('FAQ') ?true:false:false,
          showTeams:this.props.location.state?this.props.location.state.data.pages.includes('TEAMS') ?true:false:false,
          showPermissions:this.props.location.state?this.props.location.state.data.pages.includes('PERMISSIONS') ?true:false:false,
          showSubAdmin:this.props.location.state?this.props.location.state.data.pages.includes('SUB-ADMIN') ?true:false:false,
          showProperties:this.props.location.state?this.props.location.state.data.pages.includes('PROPERTIES') ?true:false:false,
          showViews:this.props.location.state?this.props.location.state.data.pages.includes('VIEWS') ?true:false:false,
          showAddUser:this.props.location.state?this.props.location.state.data.pages.includes('ADD-USER') ?true:false:false,
          showClients:this.props.location.state?this.props.location.state.data.pages.includes('CLIENTS') ?true:false:false,
          showCompanies:this.props.location.state?this.props.location.state.data.pages.includes('COMPANIES') ?true:false:false,
          showAnoncements:this.props.location.state?this.props.location.state.data.pages.includes('ANONCEMENTS') ?true:false:false,
          showPrize:this.props.location.state?this.props.location.state.data.pages.includes('PRIZES')?true:false:false,
          showNewLaunches:this.props.location.state?this.props.location.state.data.pages.includes('NEW-LAUNCHES') ?true:false:false,
        };

      constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        } 
      }

      update = () => {
        const {permission_en,permission_ar,showProperties,showDeals
        ,showAbout,showTeams,showSubAdmin,showProject,showCategories,
      showUsers,showContactUs,showCountries,showFaq,showDashboard,
    showPermissions,showSettings,showLocations,showViews,showAddUser,showClients,
    showCompanies,showAnoncements,showNewLaunches,showPrize} = this.state
        if(hasValue(permission_en)&&hasValue(permission_ar)){
            let l = message.loading('Wait..', 2.5)
            const data={
                'permission_en':permission_en,
                'permission_ar':permission_ar,
            }
            let permissions =[];
            
            if(showPrize ===true){
              permissions.push('PRIZES')
            }
            if(showCompanies ===true){
              permissions.push('COMPANIES')
            }
            if(showAnoncements ===true){
              permissions.push('ANONCEMENTS')
            }
            if(showNewLaunches ===true){
              permissions.push('NEW-LAUNCHES')
            }
            if(showViews ===true){
              permissions.push('VIEWS')
            }
            if(showClients ===true){
              permissions.push('CLIENTS')
            }
            if(showAddUser ===true){
              permissions.push('ADD-USER')
            }
            if(showProperties ===true){
              permissions.push('PROPERTIES')
            }
            if(showDeals ===true){
              permissions.push('DEALS')
            }
            if(showUsers ===true){
              permissions.push('USERS')
            }
            if(showAbout ===true){
              permissions.push('ABOUT')
            }
            if(showTeams ===true){
              permissions.push('TEAMS')
            }
            if(showSubAdmin ===true){
              permissions.push('SUB-ADMIN')
            }
            if(showProject ===true){
              permissions.push('PROJECTS')
            }
            if(showCategories ===true){
              permissions.push('CATEGORIES')
            }
            if(showContactUs ===true){
              permissions.push('CONTACT-US')
            }
            if(showCountries ===true){
              permissions.push('COUNTRIES')
            }
            if(showFaq ===true){
              permissions.push('FAQ')
            }
            if(showDashboard ===true){
              permissions.push('DASHBOARD')
            }
            if(showPermissions ===true){
              permissions.push('PERMISSIONS')
            }
            if(showSettings ===true){
              permissions.push('SETTINGS')
            }
            if(showLocations ===true){
              permissions.push('LOCATIONS')
            }
            
            data.pages = permissions
            console.log(data)
            axios.put(`${BASE_END_POINT}permissions/${this.props.location.state.data.id}`,JSON.stringify(data),{
            headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            console.log("CITY UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
     }

     delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}permissions/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("permission DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
     }

      submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.update()
      };
    
      changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
      };
      showPrizeOn =()=>{
        this.setState({
          showPrize: true,
        })
      }
      showPrizeOff =()=>{
        this.setState({
          showPrize: false,
        })
      }
      showCompaniesOn =()=>{
        this.setState({
          showCompanies: true,
        })
      }
      showCompaniesOff =()=>{
        this.setState({
          showCompanies: false,
        })
      }
      showAnoncementsOn =()=>{
        this.setState({
          showAnoncements: true,
        })
      }
      showAnoncementsOff =()=>{
        this.setState({
          showAnoncements: false,
        })
      }
      showNewLaunchesOn =()=>{
        this.setState({
          showNewLaunches: true,
        })
      }
      showNewLaunchesOff =()=>{
        this.setState({
          showNewLaunches: false,
        })
      }
      showAddUserOn =()=>{
        this.setState({
          showAddUser: true,
        })
      }
      showAddUserOff =()=>{
        this.setState({
          showAddUser: false,
        })
      }
      showClientsOn =()=>{
        this.setState({
          showClients: true,
        })
      }
      showClientsOff =()=>{
        this.setState({
          showClients: false,
        })
      }
      showViewsOn =()=>{
        this.setState({
          showViews: true,
        })
      }
      showViewsOff =()=>{
        this.setState({
          showViews: false,
        })
      }
      showSettingsOn =()=>{
        this.setState({
          showSettings: true,
        })
      }
      showSettingsOff =()=>{
        this.setState({
          showSettings: false,
        })
      }
      showDashboardOn =()=>{
        this.setState({
          showDashboard: true,
        })
      }
      showDashboardOff =()=>{
        this.setState({
          showDashboard: false,
        })
      }
      showContactUsOn =()=>{
        this.setState({
          showContactUs: true,
        })
      }
      showContactUsOff =()=>{
        this.setState({
          showContactUs: false,
        })
      }
      showDealsOn =()=>{
        this.setState({
          showDeals: true,
        })
      }
      showDealsOff =()=>{
        this.setState({
          showDeals: false,
        })
      }
      showProjectOn =()=>{
        this.setState({
          showProject: true,
        })
      }
      showProjectOff =()=>{
        this.setState({
          showProject: false,
        })
      }
      showCountriesOn =()=>{
        this.setState({
          showCountries: true,
        })
      }
      showCountriesOff =()=>{
        this.setState({
          showCountries: false,
        })
      }
      showAboutOn =()=>{
        this.setState({
          showAbout: true,
        })
      }
      showAboutOff =()=>{
        this.setState({
          showAbout: false,
        })
      }
      showLocationsOn =()=>{
        this.setState({
          showLocations: true,
        })
      }
      showLocationsOff =()=>{
        this.setState({
          showLocations: false,
        })
      }
      showUsersOn =()=>{
        this.setState({
          showUsers: true,
        })
      }
      showUsersOff =()=>{
        this.setState({
          showUsers: false,
        })
      }
      showCategoriesOn =()=>{
        this.setState({
          showCategories: true,
        })
      }
      showCategoriesOff =()=>{
        this.setState({
          showCategories: false,
        })
      }
      showFaqOn =()=>{
        this.setState({
          showFaq: true,
        })
      }
      showFaqOff =()=>{
        this.setState({
          showFaq: false,
        })
      }
      showTeamsOn =()=>{
        this.setState({
          showTeams: true,
        })
      }
      showTeamsOff =()=>{
        this.setState({
          showTeams: false,
        })
      }
      showPermissionsOn =()=>{
        this.setState({
          showPermissions: true,
        })
      }
      showPermissionsOff =()=>{
        this.setState({
          showPermissions: false,
        })
      }
      showSubAdminOn =()=>{
        this.setState({
          showSubAdmin: true,
        })
      }
      showSubAdminOff =()=>{
        this.setState({
          showSubAdmin: false,
        })
      }
      showPropertiesOn =()=>{
        this.setState({
          showProperties: true,
        })
      }
      showPropertiesOff =()=>{
        this.setState({
          showProperties: false,
        })
      }

    
    
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('PERMISSIONS')) return <Redirect to='/Home' />
  return (
    <div className="App">
       
        <MDBContainer> 
            <MDBRow>
             
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard">
                          <CardHeader></CardHeader>
                          <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                              <div className="topIcon">
                                  <span className="material-icons" style={{color:'white',fontSize:23}}>lock</span>
                              </div>
                              <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                              marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                              <p>{this.state.actionType==="VIEW"?allStrings.permissionInfo:allStrings.edit}</p>
                              </div>
                          </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    <div className="grey-text">
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.permission_en} type="text" id="form645" name="permission_en" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) => e.currentTarget.value = e.currentTarget.value.replace(/[^\u0600-\u06FF0-9#.,@%$_^*()\/\\ _-]+/g
, '')} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.permission_ar} type="text" id="form56" name="permission_ar" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>


                                    <MDBRow style={{flexDirection:this.props.isRTL ? 'row-reverse' : 'row'}}>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showPropertiesOff}
                                              style={{background:this.state.showProperties?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showProperties?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showPropertiesOn}
                                              style={{background:this.state.showProperties?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showProperties?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Properties}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showViewsOff}
                                              style={{background:this.state.showViews?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showViews?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showViewsOn}
                                              style={{background:this.state.showViews?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showViews?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.views}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showSettingsOff}
                                              style={{background:this.state.showSettings?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showSettings?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showSettingsOn}
                                              style={{background:this.state.showSettings?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showSettings?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.setting}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showContactUsOff}
                                              style={{background:this.state.showContactUs?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showContactUs?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showContactUsOn}
                                              style={{background:this.state.showContactUs?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showContactUs?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.contactUs}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showDealsOff}
                                              style={{background:this.state.showDeals?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showDeals?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showDealsOn}
                                              style={{background:this.state.showDeals?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showDeals?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Deals}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showProjectOff}
                                              style={{background:this.state.showProject?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showProject?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showProjectOn}
                                              style={{background:this.state.showProject?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showProject?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.projects}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showCountriesOff}
                                              style={{background:this.state.showCountries?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showCountries?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showCountriesOn}
                                              style={{background:this.state.showCountries?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showCountries?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.Countries}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showAboutOff}
                                              style={{background:this.state.showAbout?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showAbout?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showAboutOn}
                                              style={{background:this.state.showAbout?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showAbout?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.about}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showLocationsOff}
                                              style={{background:this.state.showLocations?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showLocations?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showLocationsOn}
                                              style={{background:this.state.showLocations?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showLocations?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.locations}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                     
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showUsersOff}
                                              style={{background:this.state.showUsers?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showUsers?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showUsersOn}
                                              style={{background:this.state.showUsers?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showUsers?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.users}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showCategoriesOff}
                                              style={{background:this.state.showCategories?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showCategories?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showCategoriesOn}
                                              style={{background:this.state.showCategories?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showCategories?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.categories}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showFaqOff}
                                              style={{background:this.state.showFaq?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showFaq?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showFaqOn}
                                              style={{background:this.state.showFaq?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showFaq?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.questions}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showTeamsOff}
                                              style={{background:this.state.showTeams?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showTeams?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showTeamsOn}
                                              style={{background:this.state.showTeams?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showTeams?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.byitTeam}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showSubAdminOff}
                                              style={{background:this.state.showSubAdmin?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showSubAdmin?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showSubAdminOn}
                                              style={{background:this.state.showSubAdmin?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showSubAdmin?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.supervisors}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showPermissionsOff}
                                              style={{background:this.state.showPermissions?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showPermissions?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showPermissionsOn}
                                              style={{background:this.state.showPermissions?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showPermissions?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.permissions}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showClientsOff}
                                              style={{background:this.state.showClients?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showClients?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showClientsOn}
                                              style={{background:this.state.showClients?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showClients?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.clients} 
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showCompaniesOff}
                                              style={{background:this.state.showCompanies?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showCompanies?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showCompaniesOn}
                                              style={{background:this.state.showCompanies?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showCompanies?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.companies} 
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showPrizeOff}
                                              style={{background:this.state.showPrize?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showPrize?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showPrizeOn}
                                              style={{background:this.state.showPrize?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showPrize?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.prizes} 
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showAnoncementsOff}
                                              style={{background:this.state.showAnoncements?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showAnoncements?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showAnoncementsOn}
                                              style={{background:this.state.showAnoncements?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showAnoncements?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.anoncements} 
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showNewLaunchesOff}
                                              style={{background:this.state.showNewLaunches?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showNewLaunches?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showNewLaunchesOn}
                                              style={{background:this.state.showNewLaunches?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showNewLaunches?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.newLaunches} 
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showDashboardOff}
                                              style={{background:this.state.showDashboard?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showDashboard?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showDashboardOn}
                                              style={{background:this.state.showDashboard?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showDashboard?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.dashboard}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                      <MDBCol xl ="3" lg = "4" md="6" sm="12">
                                        <MDBRow>
                                          <MDBCol md="6">
                                            <div className="checking">
                                              <div className="checkingLeft" onClick={this.showAddUserOff}
                                              style={{background:this.state.showAddUser?'transparent':"mediumseagreen"}}>
                                                <span style={{
                                                  padding: '5px 25px',
                                                  display: 'inline-block',
                                                  color:this.state.showAddUser?'crimson':'#fff',
                                                  fontWeight: '600'
                                                }}>{allStrings.no}</span>
                                              </div>
                                              <div className="checkingRight" onClick={this.showAddUserOn}
                                              style={{background:this.state.showAddUser?"mediumseagreen":'transparent'}}>
                                                  <span style={{
                                                    color:this.state.showAddUser?'#fff':'crimson',
                                                    padding:' 4px 10px',
                                                    display: 'inline-block',
                                                    fontWeight: '600'}}>{allStrings.yes}</span>
                                              </div>
                                            </div>
                                          </MDBCol>
                                          <MDBCol md="6">
                                            <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                                              {allStrings.AddUser}
                                            </label>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBCol>
                                    </MDBRow>

                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                    {this.state.actionType==="EDIT"&&
                                        <MDBBtn
                                        type="submit" style={{background:'#00B483'}}
                                        rounded
                                        className="btn-block z-depth-1a"
                                        >
                                            <MDBIcon icon="edit" className="mr-2" />
                                            {allStrings.update}          
                                        </MDBBtn>
                                    }{this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                            {allStrings.remove}
                                        </MDBBtn>
                                    }
                                        
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
           
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(permissionInfo)
  );