import React from 'react';
import './add anoncement.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message,TreeSelect} from "antd";
import { UploadOutlined } from '@ant-design/icons';
const { TreeNode } = TreeSelect;
class add extends React.Component {
  state = {
    selectedFile: null,
    img_ar: null,
    img_arName:'',
    img_arType:'data',
    img_en: null,
    img_enName:'',
    img_enType:'data',
    newLaunch:null,
    type:'NORMAL',
    property:null
  };

  constructor(props){
    super(props)
    this.fileChangedHandlerAr = this.fileChangedHandlerAr.bind(this);
    this.fileChangedHandlerEn = this.fileChangedHandlerEn.bind(this);
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  add = () => {
    const {img_ar,img_en,type,newLaunch,property} = this.state
    if(img_ar != null && img_en != null){
      let l = message.loading('Wait..', 1.5)
        var data = new FormData()
        data.append('img_ar',img_ar); 
        data.append('img_en',img_en); 
        if(type === "NEW-LAUNCH"){
          data.append('newLaunch',newLaunch); 
        }
        if(type === "PROPERTY"){
          data.append('property',property); 
        }
        axios.post(`${BASE_END_POINT}anoncements`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        },
        })
        .then(response=>{
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }
  
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.add()
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  
  fileChangedHandlerAr = event => {
    this.setState({
      img_ar: event.target.files[0],
      img_arName: event.target.files[0].name,
      img_arType:'data'
    })
  }
  removeFileAr = () => {
    this.setState({
        img_ar:null,
        img_arName:'',
        img_arType:"url"
    });
  }
  fileChangedHandlerEn = event => {
    this.setState({
      img_en: event.target.files[0],
      img_enName: event.target.files[0].name,
      img_enType:'data'
    })
  }
  removeFileEn = () => {
    this.setState({
        img_en:null,
        img_enName:'',
        img_enType:"url"
    });
  }
  
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('ANONCEMENTS')) return <Redirect to='/Home' />
    return (
      <div className="App">
          
          <MDBContainer>
              <MDBRow>
                  <MDBCol xl ="12" lg = "12" md="12" sm="12">
                      <div className="infoCard">
                        <Card className="editCard">
                          <CardMedia
                          style={{height:"0px"}}
                          />
                          <CardHeader></CardHeader>
                          <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon">
                                <span class="material-icons" style={{color:'white',fontSize:23}}>featured_video</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                            <p>{allStrings.add}</p>
                            </div>
                          </div>
                          <CardContent style={{minHeight:"390px",outline:'none'}}>
                            <MDBContainer>
                                <MDBRow>
                                    <MDBCol md="12">
                                    <form
                                    className="needs-validation"
                                    onSubmit={this.submitHandler}
                                    noValidate
                                    >
                                      
                                    <div className="grey-text">
                                      <MDBRow>
                                        <MDBCol md="12">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                            <TreeSelect 
                                                virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.type}
                                                onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({type:value});
                                                }} 
                                            >
                                                <TreeNode value="NORMAL" title={allStrings.normalAnoncement} key="NORMAL" />
                                                <TreeNode value="NEW-LAUNCH" title={allStrings.newLaunch} key="NEW-LAUNCH" />
                                                <TreeNode value="PROPERTY" title={allStrings.Properties} key="PROPERTY" />
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                      </MDBRow>
                                      {this.state.type === "PROPERTY" &&
                                      <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form6025" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.propertyId}</label>
                                            <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.property} name="property" type="text" id="form6025"  className="form-control" onChange={this.changeHandler} required validate />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                      </MDBRow>
                                      }{this.state.type === "NEW-LAUNCH" &&
                                      <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form6025" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.newLaunchId}</label>
                                            <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.newLaunch} name="newLaunch" type="text" id="form6025"  className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                      </MDBRow>
                                      }
                                      <br></br>
                                      <br></br>
                                      <MDBRow>
                                        <MDBCol md="12">
                                          <div className="fileUpload2"> 
                                            <input  key={this.state.img_ar} type='file' className="upImgs2" onChange={this.fileChangedHandlerAr} ></input>
                                              <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.uploadAr}</span></span>
                                          </div>
                                          <div className="preview">
                                            {this.state.img_ar&&
                                              <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img alt="" src={URL.createObjectURL(this.state.img_ar)}  className="previewImg2"/>
                                                  <span className="imgName">{this.state.img_arName}</span>
                                                  <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                  onClick={() => this.removeFileAr()}  ></MDBIcon>
                                              </div>
                                            }
                                          </div>
                                        </MDBCol>
                                      </MDBRow>
                                      <MDBRow>
                                        <MDBCol md="12">
                                          <div className="fileUpload2"> 
                                            <input  key={this.state.img_en} type='file' className="upImgs2" onChange={this.fileChangedHandlerEn} ></input>
                                              <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.uploadEn}</span></span>
                                          </div>
                                          <div className="preview">
                                            {this.state.img_en&&
                                              <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img alt="" src={URL.createObjectURL(this.state.img_en)}  className="previewImg2"/>
                                                  <span className="imgName">{this.state.img_enName}</span>
                                                  <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                  onClick={() => this.removeFileEn()}  ></MDBIcon>
                                              </div>
                                            }
                                          </div>
                                        </MDBCol>
                                      </MDBRow>
                                    </div>
                                        <br></br>
                                        <br></br>
                                      <div className="text-center">
                                        <MDBBtn
                                            type="submit" style={{background:'#233b5b'}}
                                            rounded
                                            className="btn-block z-depth-1a"
                                        >
                                            <MDBIcon icon="plus" className="mr-2" />
                                                {allStrings.add}        
                                        </MDBBtn>
                                      </div>
                                    </form>
                                  </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                          </CardContent>
                        </Card>
                    </div>
                </MDBCol>
              </MDBRow>
          </MDBContainer>
        
      </div> 
    )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(add)
  );